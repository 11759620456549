import { useState, useEffect } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Box, Button } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import Swal from "sweetalert2";
import TopHeader from "../Home/TopHeader";
import HistorySidebar from "../Home/HistorySidebar";
import {
  getRequestWithTokenWithoutData,
  patchRequestWithTokenWithData,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReceiptPopup from "../Common/ReceiptPopup";

const columns = [
  {
    accessorKey: "id",
    header: "ID",
    render: (row) => row.index + 1,
  },
  // {
  //   accessorKey: "startDate",
  //   header: "Start Date",
  // },
  {
    accessorKey: "causeNames",
    header: "Cause Name",
  },
  {
    accessorKey: "donatedAmt",
    header: "Amount Donated",
  },
  {
    accessorKey: "freqName",
    header: "Frequency",
  },

  {
    accessorKey: "statusName",
    header: "Status",
    Cell: ({ cell }) => (
      <Box
        sx={(theme) => ({
          backgroundColor:
            cell.getValue() === "Active"
              ? "#DAF4EB"
              : cell.getValue() === "Stop"|| cell.getValue() === "Failed"
              ? "#FDE4E4"
              : cell.getValue() === "Pending"
              ? "#FFE0A1"
              : "inherit",
          color:
            cell.getValue() === "Active"
              ? theme.colors.green[9]
              : cell.getValue() === "Stop" || cell.getValue() === "Failed"
              ? "#FF0000"
              : cell.getValue() === "Pending"
              ? "#FFA200"
              : "inherit",
          borderRadius: "4px",
          padding: "4px",
          width: "80px",
          textAlign: "center",
        })}
      >
        {cell.getValue()}
      </Box>
    ),
  },
];

const RecurringDonation = () => {
  const [data, setData] = useState([]);

  const [showReceiptPopup, setShowReceiptPopup] = useState(false);

  const [receiptPopupData, setReceiptPopupData] = useState(null);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const getRecurringDonationHistory = async () => {
    try {
      const url = new URL(
        `${TQ_URL}/getAllRecurringDonationListByLoggedInUser`
      );

      console.log(url.href);
      url.search = new URLSearchParams();
      url.searchParams.set("start", `${pagination.pageIndex}`);
      url.searchParams.set("size", `${pagination.pageSize}`);

      const response = await getRequestWithTokenWithoutData(url.toString());
      console.log(response);

      const dataWithSerialNumber = response.dataList.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      setData(dataWithSerialNumber);
      setRowCount(response.totalCount);
    } catch (error) {
      console.error(
        "Error in fetching the recurring Donation's history:",
        error
      );
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const handleViewReceipt = (rowdata) => {
    console.log("view reciept called ", rowdata.original.fundraiserDetailDTO);
    setShowReceiptPopup(true);
    setReceiptPopupData(rowdata.original);
  };

  const handleStopRecurringDonation = (rowId, statusId) => {
    console.log(rowId);
    console.log(statusId);

    Swal.fire({
      title: "Are you sure?",
      text: "This action will update the status for your fundraiser",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: false,
    }).then((result) => {
      if (result.isConfirmed) {
        handleStopRecurringDonationProcess(rowId, statusId);
      } else {
      }
    });
  };

  const handleStopRecurringDonationProcess = async (rowId, status) => {
    try {
      let request = {
        recurringDonationId: rowId,
        status: status,
      };
      const response = await patchRequestWithTokenWithData(
        `${TQ_URL}/stopRecurringDonation`,
        request
      );
      console.log(response);

      if (status == 4) {
        const updatedData = data.map((item) => {
          if (item.recurringMoneyDonationId == rowId) {
            return { ...item, statusName: "Stop" };
          }
          return item;
        });

        setData(updatedData);
      }

      console.log(data);

      successRecurringDonationStopped(status);
    } catch (error) {
      console.error("Error stopping fundraising:", error);
    }
  };
  const successRecurringDonationStopped = (status) => {
    if (status == 4) toast.success("Recurring Donation Stopped Successfully!");
    else toast.success("Recurring Donation Deleted Successfully!");
  };

  useEffect(() => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    getRecurringDonationHistory();
  }, [
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
    sorting,
  ]);

  const table = useMantineReactTable({
    columns,
    data,
    enableRowActions: true,
    positionActionsColumn: "last",

    initialState: { showColumnFilters: false },
    rowCount,

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Error loading data" }
      : undefined,

    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
        <Button
          onClick={() => {
            // Add your logic for handling "Stop" button click
            console.log("Stop:", row);
            console.log(row.original.recurringMoneyDonationId);
            handleStopRecurringDonation(
              row.original.recurringMoneyDonationId,
              4
            );
          }}
          variant="outline"
        >
          Stop
        </Button>

        <ActionIcon
          color="red"
          onClick={async () => {
            const newData = [...data];
            newData.splice(row.index, 1);
            setData(newData);
            console.log(row.original.recurringMoneyDonationId);

          
            handleStopRecurringDonation(
              row.original.recurringMoneyDonationId,
              7
            );
          }}
        >
          <IconTrash />
        </ActionIcon>
      </Box>
    ),
  });

  return (
    <>
      <TopHeader />
      <div className="flex">
        <div className="md:w-64 w-1/12 absolute md:relative">
          <HistorySidebar />
        </div>
        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6 ">
              <div className="md:text-left text-center">
                <h2 className="text-2xl font-bold">Recurring Donation</h2>
              </div>
              <div className="flex items-center space-x-4 mt-6 md:mt-0 justify-center"></div>
            </div>
          </div>
          <div className="container md:mx-auto md:py-16 py-6">
            <MantineReactTable table={table} />
            {showReceiptPopup && (
              <ReceiptPopup
                onClose={() => {
                  setShowReceiptPopup(false);
                  setReceiptPopupData(null);
                }}
                rowData={receiptPopupData}
              />
            )}
          </div>
        </main>
      </div>
      <ToastContainer theme="colored" />
    </>
  );
};

export default RecurringDonation;
