import React from "react";
import TopHeader from "../Home/TopHeader";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getRequestWithTokenWithDataWithAuth } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}

const MoneyDetails = () => {
  const [open, setOpen] = React.useState(null);
  const [money, setMoney] = useState("");

  const handleOpen = (value) => setOpen(open === value ? null : value);

  const getTotalMoney = async () => {
    try {
      const response = await getRequestWithTokenWithDataWithAuth(
        `${TQ_URL}/getbalance`
      );
      setMoney(response.availableAmount);
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  useEffect(() => {
    if (!money) {
      getTotalMoney();
    }
  }, [money]);

  const FAQData = [
    {
      id: 101,
      question: "Checkout the list of all transections",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac fringilla dolor. Vestibulum efficitur dui a urna accumsan, at tincidunt quam semper. Curabitur bibendum, justo et dapibus pharetra, libero tortor hendrerit libero.",
    },
    {
      id: 102,
      question: "Cause Specifc Donations",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac fringilla dolor. Vestibulum efficitur dui a urna accumsan, at tincidunt quam semper. Curabitur bibendum, justo et dapibus pharetra, libero tortor hendrerit libero.",
    },
    {
      id: 103,
      question: "Reports",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac fringilla dolor. Vestibulum efficitur dui a urna accumsan, at tincidunt quam semper. Curabitur bibendum, justo et dapibus pharetra, libero tortor hendrerit libero.",
    },
  ];

  return (
    <div>
      <TopHeader />
      <div className="flex">
        <div className=""></div>
        <main className="w-full verify-user">
          <div className=" mx-auto shadow-md">
            <div className="md:flex justify-between container items-center px-10 md:py-6 py-6 ">
              <div className="md:text-left text-center">
                <h2 className="text-2xl font-bold">Money Details</h2>
                <p className="text-black font-medium md:leading-10 mt-4 md:mt-0">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <div className="flex items-center space-x-4 mt-6 md:mt-0 justify-center">
                {/* <div className="user-img-box w-24 h-24 mb-4 overflow-hidden rounded-full relative cursor-pointer">
                  <img
                    src="assets/images/user-1.jpg"
                    alt="Document Preview"
                    className="object-cover w-full h-full bg-grey-500 cursor-pointer"
                  />
                </div> */}
                {/* <p>John Doe</p> */}
                {/* <svg
                  viewBox="0 0 16 16"
                  className="w-4"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <rect width="16" height="16" id="icon-bound" fill="none"></rect>
                    <polygon points="14.586,3.586 8,10.172 1.414,3.586 0,5 8,13 16,5"></polygon>
                  </g>
                </svg> */}
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* Main content  Section */}
      <div className="py-10 container mx-auto ">
        {/* First ROw */}
        <div className="md:flex  md:space-x-6">
          <div className="bg-[#F0F2F7] md:w-6/12 md:px-32 px-28  py-20 rounded-2xl mx-4 md:mx-0">
            <h1 className="primary-text-color md:text-5xl text-3xl font-semibold">
              <img
                src="assets/images/dollar.svg"
                className="absolute ml-[-70px] w-10"
              ></img>
              $ {money}
            </h1>
            <h1 className="primary-text-color text-lg font-normal pt-2">
              Total Balance
            </h1>
            <button className="btn-style-1 text-white px-8 py-1 rounded-2xl mt-6">
              Check
            </button>
          </div>
          <div className="flex md:w-6/12 space-x-2 md:space-x-6 mt-2 md:mt-0 mx-4">
            <div className="bg-gradient-to-b from-[#5E9DE4] to-[#E9CFED] w-6/12 rounded-2xl flex flex-col justify-center items-center text-center py-6 md:py-0">
              <img src="assets/images/check-list.svg" className="w-6"></img>
              <h1 className="text-white font-semibold text-xl mt-2">
                Verify Users
              </h1>
              <p className="w-1/2 text-xs mt-4 "></p>
              <Link to="/verifyusers">
                <button className="btn-style-1 text-white px-8 py-1 rounded-2xl mt-6">
                  Continue
                </button>
              </Link>
            </div>
            <div className="bg-gradient-to-b from-[#FFA797] to-[#FFC999] w-6/12 rounded-2xl flex flex-col justify-center items-center text-center">
              <img src="assets/images/layer-plus.svg" className=" w-8"></img>
              <h1 className="text-white font-semibold text-xl mt-2">
                View Causes
              </h1>

              <Link to="/list-of-causes">
                <button className="btn-style-1 text-white px-8 py-1 rounded-2xl mt-6">
                  Check
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="md:flex md:space-x-6 py-10">
          <div className="bg-gradient-to-b from-[#5E9DE4] to-[#E9CFED] w-3/12 rounded-2xl flex flex-col justify-center items-center text-center   py-20  md:py-0">
            <img src="assets/images/check-list.svg" className="w-6"></img>
            <h1 className="text-white font-semibold text-xl mt-2">
              All Transactions
            </h1>
            <p className="w-1/2 text-xs mt-4 "></p>
            <Link to="/all-transactions">
              <button className="btn-style-1 text-white px-8 py-1 rounded-2xl mt-6">
                Continue
              </button>
            </Link>
          </div>

          <div className="bg-gradient-to-b from-[#FFA797] to-[#FFC999] w-3/12 rounded-2xl flex flex-col justify-center items-center text-center">
            <img src="assets/images/layer-plus.svg" className=" w-8"></img>
            <h1 className="text-white font-semibold text-xl mt-2">
              All Fundraisers
            </h1>

            <Link to="/all-fundraiser">
              <button className="btn-style-1 text-white px-8 py-1 rounded-2xl mt-6">
                Check
              </button>
            </Link>
          </div>

          <div className="bg-gradient-to-b from-[#5E9DE4] to-[#E9CFED] w-3/12 rounded-2xl flex flex-col justify-center items-center text-center   py-20">
            <img src="assets/images/check-list.svg" className="w-6"></img>
            <h1 className="text-white font-semibold text-xl mt-2">
              All Subscription
            </h1>
            <p className="w-1/2 text-xs mt-4 "></p>
            <Link to="/all-money">
              <button className="btn-style-1 text-white px-8 py-1 rounded-2xl mt-6">
                Continue
              </button>
            </Link>
          </div>

          {/* <div className="bg-gradient-to-b from-[#FFA797] to-[#FFC999] w-3/12 rounded-2xl flex flex-col justify-center items-center text-center">
            <img src="assets/images/layer-plus.svg" className=" w-8"></img>
            <h1 className="text-white font-semibold text-xl mt-2">
              View Causes
            </h1>

            <Link to="/list-of-causes">
              <button className="btn-style-1 text-white px-8 py-1 rounded-2xl mt-6">
                Check
              </button>
            </Link>
          </div> */}
        </div>

        {/* Second Row */}

        {/* <div>
          <div className="container mx-auto py-10 px-4">
            {FAQData.map((faq, index) => (
              <Accordion
                key={index}
                open={index === open}
                icon={<Icon id={index} open={open} />}
              >
                <AccordionHeader
                  onClick={() => handleOpen(index)}
                  className="money-details-accordion bg-white md:px-16 px-3 shadow-md"
                >
                  <p className="text-base">
                    <span className="font-normal me-6">{faq.id}</span>{" "}
                    {faq.question}
                  </p>
                </AccordionHeader>
                <AccordionBody>
                  <p className="text-base font-normal">{faq.answer}</p>
                </AccordionBody>
              </Accordion>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default MoneyDetails;
