import React, { useState, useEffect } from "react";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import * as yup from "yup";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { getRequestWithTokenWithoutData } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
  name: yup.string(),
  email: yup.string().email("Invalid email").required("Email is required"),
  subject: yup.string().required("Subject is required"),
  description: yup.string().required("Description is required"),
});

const Support = () => {
  const [config, setConfig] = useState({});
  const [causeValue, setCauseValue] = useState("");
  const [errorCause, setErrorCause] = useState("");
  const [causeList, setCauseList] = useState([]);
  const [verifiedMsg, setVerifiedMsg] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        console.log(data.partnerId);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  useEffect(() => {
    getCauseList();
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch("/config.json");
      const data = await response.json();
      setConfig(data);
    } catch (error) {
      console.error("Error loading config.json:", error);
    }
  };

  const [captchaResponse, setCaptchaResponse] = useState(null);

  const handleCaptchaResponseChange = (response) => {
    setCaptchaResponse(response);
    setVerifiedMsg("");
  };

  const getCauseList = async () => {
    try {
      const response = await axios.get(`${TQ_URL}/getAllIssueTypeList`);
      console.log(response.data);
      setCauseList(response.data);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        console.error("Error:", error.response.data.error);
       // toast.error(error.response.data.error);
      } else {
        console.error("Error in fetching the issuetype::", error);
        //toast.error("Error in fetching the issuetype:");
      }
    }
  };

  const onSubmit = async (data, event) => {
    try {
      const partnerId = config.partnerId;
      console.log(partnerId);
      if (!partnerId) {
        throw new Error("Partner ID is not defined");
      }
      if (!captchaResponse) {
        setVerifiedMsg("Please complete the reCAPTCHA verification.");
        return;
      }

      setVerifiedMsg("");

      data.captchaResponse = captchaResponse;
      console.log("Form data:", data);

      if (!causeValue) {
        setErrorCause("Please select a CauseType");
        return;
      }

      event.preventDefault();
      const response = await fetch(`${TQ_URL}/createNewTicket`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName: data.name,
          email: data.email,
          title: data.subject,
          subject: data.subject,
          causeTypeId: parseInt(causeValue),
          description: data.description,
          partnerId: parseInt(partnerId),
          captchaResponse: captchaResponse,
        }),
      });

      const res = await response.json();

      console.log("BODY >>", res);
      console.log(response);

      if (response.status === 200) {
        tikectCreated(res.message);
      } else {
        throw new Error(res.message || "Failed to create ticket");
      }
    } catch (error) {
      console.error("Error in creating ticket:", error);
      toast.error( "Error in creating ticket");
    }
  };

  const tikectCreated = (msg) => {
    toast.success(msg, {
      position: "top-right",
    });
    reset();
  };

  const onErrors = (error) => {
    console.log("page is hitting ERROR", error);
  };

  const getCauseValue = (event) => {
    const selectedCauseValue = event.target.value;
    console.log("Selected cause value>>", selectedCauseValue);
    setCauseValue(selectedCauseValue);
    setErrorCause(selectedCauseValue ? "" : "Please select a Cause");
  };

  return (
    <>
      <Header />
      <div className="h-[400px] contact-us-banner flex items-center justify-center ">
        <h2 className="md:text-4xl md:mt-100 text-xl font-bold text-white ">
          Customer Support Service
        </h2>
      </div>
      <div className="container mx-auto md:flex justify-center items-center my-10">
        <div className="flex-shrink-0 md:w-1/2 self-start">
          <div className="md:p-8 mx-4 md:mx-0 flex flex-col justify-start pb-20 ">
            <h2 className="txt-style-7 font-family-poppins text-left text-xl">
              How can We help?
            </h2>
            <div className="flex space-y-6 flex-col ">
              <div className="md:flex md:space-x-10 mt-6 md:mt-0">
                <div className="text-center icon-box">
                  <img
                    src="assets/images/email.png"
                    alt="Logo"
                    className="h-14"
                  />
                  <p className="text-sm font-semibold">Mail</p>
                </div>
                <div className="flex flex-col justify-center">
                  <h1 className="text-xl font-semibold mt-2">{config.email}</h1>
                  <h1 className="text-sm font-normal">
                    Send us an email for any assistance or inquiry.
                  </h1>
                </div>
              </div>

              <div className="md:flex md:space-x-10 mt-6 md:mt-0">
                <div className="text-center icon-box">
                  <img
                    src="assets/images/call.png"
                    alt="Logo"
                    className="h-14 "
                  />
                  <p className="text-sm font-semibold">Phone</p>
                </div>
                <div className="flex flex-col justify-center">
                  <h1 className="text-xl font-semibold">
                    {config.phoneNumber}
                  </h1>
                  <h1 className="text-sm font-normal">
                    Call us to get in touch with our team.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-grow md:ml-8 m-2">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-lg mx-auto border py-20 px-10 rounded-lg bg-white"
          >
            <h2 className="text-3xl font-extrabold mb-8 ">Create New Ticket</h2>

            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-bold mb-1">
                Name
              </label>
              <input
                type="text"
                id="name"
                {...register("name")}
                className={`border p-2 w-full shadow-md rounded-lg ${
                  errors.name && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.name?.message}
              </p>
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-bold mb-1">
                Email
              </label>
              <input
                type="text"
                id="email"
                {...register("email")}
                className={`border p-2 w-full shadow-md rounded-lg ${
                  errors.email && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.email?.message}
              </p>
            </div>

            <div className="mb-4">
              <select
                onChange={getCauseValue}
                value={causeValue}
                className={`bg-gray-50 border border-gray-300 text-black-900 font-semibold text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block mt-7 w-full p-2.5 ${
                  errorCause && "border-red-500"
                }`}
              >
                <option value="">Select IssueType</option>
                {causeList.map((cause) => (
                  <option key={cause.issueId} value={cause.issueId}>
                    {cause.issueName}
                  </option>
                ))}
              </select>

              {errorCause && <p style={{ color: "red" }}>{errorCause}</p>}
            </div>

            <div className="mb-4">
              <label htmlFor="subject" className="block text-sm font-bold mb-1">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                {...register("subject")}
                className={`border p-2 w-full shadow-md rounded-lg ${
                  errors.subject && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.subject?.message}
              </p>
            </div>

            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-sm font-bold mb-1"
              >
                Description
              </label>
              <textarea
                id="description"
                rows={3}
                style={{ resize: "none" }}
                {...register("description")}
                className={`border p-2 w-full shadow-md rounded-lg ${
                  errors.description && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.description?.message}
              </p>
            </div>
            <ReCAPTCHA
              sitekey="6LeaQ4QpAAAAACwPHgef7yz2B3tgaNqtNwsevXi9"
              onChange={handleCaptchaResponseChange}
            />
            <span className="text-red-500 text-sm mt-1">{verifiedMsg}</span>

            <div className="flex justify-center mt-10">
              <button
                type="submit"
                className="bg-blue-500 text-white mt-4 px-6 py-2 rounded btn-style-4"
              >
                Submit
              </button>
            </div>
          </form>

          <ToastContainer theme="colored" />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Support;
