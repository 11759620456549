import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Header from "../Home/Header";
import ProgressBar from "../Common/ProgressBar";
import Footer from "../Home/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  amount: yup
    .number()
    .required("Amount is required")
    .positive("Amount should be positive or non-zero")
    .typeError("Amount should not be empty"),

  fullName: yup
    .string()
    .min(5, "Name must be at least 5 characters")
    .max(50, "Name must be at most 50 characters")
    .required("Full Name is Required *"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is Required *"),
  phone: yup
    .string()
    .typeError("Phone must be a number")
    .test("only-digit", "Phone must be a number", (value) =>
      /^[\d+\-]+$/.test(value)
    )
    .test("min-length", "Phone number should be at least 8 digits", (value) =>
      /^[\d+-]{8,}$/.test(value)
    )
    .required("Phone is Required *"),
});

const TransactionDetails = ({ onComplete }) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const fundraiserId = state?.fundraiserId;
  const fundraiserName = state?.fundraiserName;

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  console.log("selected caused:", fundraiserId);
  console.log("selected caused:", fundraiserName);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    onComplete();
  }, [onComplete]);

  const onSubmitHandler = (data) => {
    const values = {
      fundraiserId: fundraiserId,
      fundraiserName: fundraiserName,
      amount: data.amount,
      phone: data.phone,
      email: data.email,
      donarName: data.fullName,
      anonymously: data.anonymously,
    };

    if (fundraiserId != null && fundraiserName != null) {
      navigate("/stripepay", { state: values });
      successFormSubmit();
    } else toast.error("Something went wrong");
    console.log(data);
  };

  const [showSubmissionPopup, setShowSubmissionPopup] = useState(false);
  const [popupFields, setPopupFields] = useState({
    field1: "",
    field2: "",
    field3: "",
  });

  const handlePopupFieldChange = (fieldName, value) => {
    setPopupFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  const onSubmitPopup = () => {
    setShowSubmissionPopup(false);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("accessToken");
    const storedUserData = localStorage.getItem("userData");

    if (storedToken) {
      console.log("Token fetched from localStorage:", storedToken);
      setIsLoggedIn(true);
    } else {
      console.log("No token found in localStorage.");
      setIsLoggedIn(false);
    }

    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      console.log("UserData fetched from localStorage:", userData);

      setValue("amount", ""); // Assuming 'amount' is not present in userData
      setValue("fullName", userData.userName);
      setValue("email", userData.email);
      if (userData.phone == null) setValue("phone", null);
      else {
        const phoneNumberWithoutCode = userData.phone.replace("+91", "").trim();
        setValue("phone", phoneNumberWithoutCode);
      }
    } else {
      console.log("No userData found in localStorage.");
    }
  }, [setValue]);

  const successFormSubmit = () => toast.success("Form Submitted Successfully!");

  return (
    <>
      <Header />
      <ProgressBar value={30} size="sm" color="blue" />

      <h2 className="md:text-3xl text-xl font-bold mb-4 text-center uppercase py-10">
        Donate Money For {fundraiserName}
      </h2>

      <div className="md:pb-10 md:flex justify-between">
        <div className="2xl:w-1/4 lg:w-1/3 md:me-20 mx-10 md:mx-0">
          <img
            src="assets/images/handWithCoin.png"
            className="md:w-full md:mx-16 w-94 h-82"
            style={{ width: "94%", height: "82%" }}
          />
        </div>

        <div className="md:w-2/3 m-4 md:m-0">
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            className="fundraiser-for-other-form md:py-12 md:px-20 p-6 md:me-20"
          >
            {/* Amount */}
            <div className="mb-6 md:flex">
              <label>Amount*</label>
              <div className="w-full">
                <input
                  {...register("amount")}
                  placeholder="Amount"
                  type="number"
                  onInput={(e) => {
                    if (e.target.value < 1) {
                      e.target.value = "";
                    }
                  }}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.amount && "border-red-500"
                  }`}
                  style={{ appearance: "textfield" }}
                />
                <p className="text-red-500 text-sm mt-1">
                  {errors.amount?.message}
                </p>
              </div>
            </div>

            {/* Full Name */}
            <div className="mb-6 md:flex">
              <label>Full name*</label>
              <div className="w-full">
                <input
                  {...register("fullName")}
                  placeholder="Full name"
                  type="text"
                  disabled={isLoggedIn}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.fullName && "border-red-500"
                  }`}
                />
                <p className="text-red-500 text-sm mt-1">
                  {errors.fullName?.message}
                </p>
              </div>
            </div>

            {/* Email */}
            <div className="mb-6 md:flex">
              <label>Email*</label>
              <div className="w-full">
                <input
                  {...register("email")}
                  placeholder="Email"
                  type="email"
                  disabled={isLoggedIn}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.email && "border-red-500"
                  }`}
                />
                <p className="text-red-500 text-sm mt-1">
                  {errors.email?.message}
                </p>
              </div>
            </div>

            {/* Phone */}
            <div className="mb-6 md:flex">
              <label>Contact Number*</label>
              <div className="w-full">
                <input
                  {...register("phone")}
                  placeholder="Phone"
                  type="text"
                  maxLength={12}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.phone && "border-red-500"
                  }`}
                />
                <p className="text-red-500 text-sm mt-1">
                  {errors.phone?.message}
                </p>
              </div>
            </div>

            {/* Optional Terms and Conditions Checkbox */}
            <div className="mb-6 md:flex justify-between">
              <div>{/* Optional content */}</div>

              <div className="md:w-4/6">
                <input
                  {...register("anonymously")}
                  type="checkbox"
                  className={`mr-2`}
                />
                <label className="text-dark-200 italic">
                  Send donation anonymously
                </label>
                <p className="text-red-500 text-sm mt-1">
                  {errors.anonymously?.message}
                </p>
                <div className="flex w-full md:justify-between justify-center items-center">
                  {/* Submit Button */}
                  <button
                    type="submit"
                    className="md:w-1/3 w-1/2 text-white p-2 rounded-md btn-style-1 mt-4 "
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Submission Popup */}
      {showSubmissionPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center fade-in-bottom">
          <div className="bg-white p-6 rounded-3xl max-w-2xl w-full">
            <button
              onClick={() => setShowSubmissionPopup(false)}
              className="text-gray-700 cursor-pointer flex justify-end w-full mb-6"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {/* Popup Fields */}
            <div className="py-10 px-5">
              <div className="mb-6 flex items-center">
                <label
                  htmlFor="documentField"
                  className="block font-semibold mb-1 w-1/3"
                >
                  Upload Document
                </label>
                <input
                  type="text"
                  id="documentField"
                  value={popupFields.field1}
                  onChange={(e) =>
                    handlePopupFieldChange("field1", e.target.value)
                  }
                  className="w-2/3 p-2 border border-gray-300 rounded-md "
                />
              </div>
              <div className="mb-6 flex items-center">
                <label
                  htmlFor="fundraiserGoalField"
                  className="block font-semibold mb-1  w-1/3"
                >
                  Fundraiser Goal
                </label>
                <input
                  type="text"
                  id="fundraiserGoalField"
                  value={popupFields.field2}
                  onChange={(e) =>
                    handlePopupFieldChange("field2", e.target.value)
                  }
                  className="w-2/3 p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-6 flex items-center">
                <label
                  htmlFor="targetDateField"
                  className="block font-semibold mb-1  w-1/3"
                >
                  Target Date
                </label>
                <input
                  type="text"
                  id="targetDateField"
                  value={popupFields.field3}
                  onChange={(e) =>
                    handlePopupFieldChange("field3", e.target.value)
                  }
                  className="w-2/3 p-2 border border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="flex w-full ">
              <span className="block font-semibold mb-1  w-1/3"></span>
              <button
                onClick={onSubmitPopup}
                className="w-1/3 text-white p-2 rounded-md btn-style-1 mt-4 "
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer theme="colored" />
      <Footer />
    </>
  );
};

export default TransactionDetails;
