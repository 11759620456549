import "./App.css";
import React, { useState, useEffect } from "react";
import ChangePassword from "./Components/Login/ChangePassword";
import CreateAccount from "./Components/Profile/CreateAccount";
import CreateProfile from "./Components/Profile/CreateProfile";
import AddAddressDetails from "./Components/Profile/AddAddressDetails";
import ForgetPassword from "./Components/Login/ForgetPassword";
import AddBankDetails from "./Components/Profile/AddBankDetails";
import VerifyAddress from "./Components/Profile/VerifyAddress";
import Success from "./Components/Profile/Success";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
  HashRouter,
} from "react-router-dom";
import LoginScreen from "./Components/Login/LoginScreen";
import HomePage from "./Components/Home/HomePage";
import ComingSoon from "./Components/Home/ComingSoon";
import CreateFundraiser from "./Components/CreateFundraiser/CreateFundraiser";
import BrowseFundraiser from "./Components/BrowseFundraiser/BrowseFundraiser";
import AboutUs from "./Components/AboutUs/AboutUs";
import ContactUs from "./Components/ContactUs/ContactUs";
import Support from "./Components/ContactUs/Support";
import Other from "./Components/CreateFundraiser/Other";
import Self from "./Components/CreateFundraiser/Self";
import Fundraiser from "./Components/BrowseFundraiser/Fundraiser";
import DonateNow from "./Components/BrowseFundraiser/DonateNow";
import TransactionDetails from "./Components/BrowseFundraiser/TransactionDetails";
import VerifyUser from "./Components/AdminDashboard/VerifyUser";
import VerifiedUser from "./Components/AdminDashboard/VerifiedUser";
import ReejectedUser from "./Components/AdminDashboard/ReejectedUser";
import VerifyFundraiser from "./Components/AdminDashboard/VerifyFundraiser";
import VerifyUsers from "./Components/Profile/VerifyUsers";
import VerifySuccess from "./Components/Profile/VerifySuccess";
import DonorsHistory from "./Components/AdminDashboard/DonorsHistory";
import FundraisersHistory from "./Components/AdminDashboard/FundraiserHistory";
import RecurringDonation from "./Components/AdminDashboard/RecurringDonation";
import HowWeWork from "./Components/HowWeWork/HowWeWork";
import HowWeWorkModal from "./Components/HowWeWork/HowWeWorkModal";
import ViewCause from "./Components/Cause/ViewCause";
import MoneyDetails from "./Components/MoneyDetails/MoneyDetails";
import AllMoney from "./Components/MoneyDetails/AllMoney";
import TotalMoney from "./Components/MoneyDetails/TotalMoney";
import AllTransactions from "./Components/MoneyDetails/AllTransactions";
import DonationCause from "./Components/MoneyDetails/DonationCause";
import AllFundraisers from "./Components/MoneyDetails/AllFundraisers";
import StripePayments from "./Components/payment-getways/StripePayments";
import StripeStatus from "./Components/payment-getways/StripeStatus";
import StripeRecurringPayment from "./Components/payment-getways/StripeRecurringPayment";
import StripeRecurringStatus from "./Components/payment-getways/StripeRecurringStatus";
import OAuth2RedirectHandler from "./Components/Login/OAuth2RedirectHandler";
import PrabaltaServicesDashboard from "./Components/Common/PrabaltaServicesDashboard";
import DonorList from "./Components/DonorManagement/DonorList";
import NgoLogin from "./Components/NGOAuth/NgoLogin";
import NgoCreateAccount from "./Components/NGOAuth/NgoCreateAccount";
import NgoAddressDetails from "./Components/NGOAuth/NgoAddressDetails";
import { DonorInformation } from "./Components/DonorManagement/DonorInformation";
import AddDonorManually from "./Components/DonorManagement/AddDonorManually";
import AllDonationList from "./Components/DonorManagement/AllDonationList";
import { CustomReceipt } from "./Components/DonorManagement/CustomReceipt";
import { FundraiserInformation } from "./Components/DonorManagement/FundraiserInformation";
import AllFundraisersList from "./Components/DonorManagement/AllFundraisersList";
import AddDonation from "./Components/DonorManagement/AddDonation";
import CreateFund from "./Components/DonorManagement/CreateFund";
import Report from "./Components/DonorManagement/Report";
import EditDonation from "./Components/DonorManagement/EditDonation";
import SocialMediaCampaign from "./Components/SocialMediaIntegration/SocialMediaCampaign";
import AnalyticsDashboard from "./Components/Analytics/AnalyticsDashboard";
import FundraiserPerformance from "./Components/Analytics/FundraiserPerformance";
import FundraiserPerformanceDetails from "./Components/Analytics/FundraiserPerformanceDetails";

import SchedulePost from "./Components/SocialMediaIntegration/SchedulePost";
import CreatePost from "./Components/SocialMediaIntegration/CreatePost";

const PrivateRoute = ({ auth: { isAuthenticated }, children }) => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (userData != null && userData.userType === "Admin") {
    navigate("/money-details");
  }

  const userAddressDetails = JSON.parse(
    localStorage.getItem("userAddressDetails")
  );

  if (userAddressDetails.addressDetails == null) navigate("/addaddressdetails");

  if (!isAuthenticated) {
    isAuthenticated = userData !== null;
  }
  console.log(isAuthenticated);
  return isAuthenticated ? children : <Navigate to="/" />;
};

const PrivateRouteForCreateFundraiser = ({
  auth: { isAuthenticated },
  children,
}) => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (userData != null && userData.userType === "Admin") {
    navigate("/money-details");
  }

  const userAddressDetails = JSON.parse(
    localStorage.getItem("userAddressDetails")
  );

  if (userAddressDetails.addressDetails == null) navigate("/addaddressdetails");

  if (!isAuthenticated) {
    isAuthenticated = userData !== null;
  }
  console.log(isAuthenticated);
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const PrivateAdminRoute = ({ auth: { isAuthenticated }, children }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  isAuthenticated = userData !== null && userData.userType === "Admin";

  console.log(isAuthenticated);

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={userData !== null ? "/" : "/money-details"} />
  );
};

const PrivateLoginRoute = ({ auth: { isAuthenticated }, children }) => {
  const navigate = useNavigate();
  if (isAuthenticated) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    isAuthenticated = userData !== null;
  }
  console.log(isAuthenticated);
  return isAuthenticated ? <Navigate to="/" /> : children;
};

const PrivateHomeAdminRoute = ({ children, ...rest }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();

  if (userData != null && userData.userType == "Admin") {
    return <Navigate to="/money-details" />;
  }
  const isAuthenticatedd = () => {
    return userData == null || userData.userType !== "Admin";
  };

  const loggedIn = localStorage.getItem("loggedIn");

  console.log("userdata--->", userData);

  if (loggedIn === "true") {
    if (isAuthenticatedd()) {
      const userAddressDetails = JSON.parse(
        localStorage.getItem("userAddressDetails")
      );

      const isAddressDetails = userAddressDetails.addressDetails == null;

      return isAddressDetails ? <Navigate to="/addaddressdetails" /> : children;
    } else {
      navigate("/money-details"); // Redirect to money-details
      return null; // Return null to avoid rendering while redirecting
    }
  } else {
    return children; // Return null to avoid rendering while redirecting
  }
};

const PrivateRouteForDonate = ({ auth: { isAuthenticated }, children }) => {
  const navigate = useNavigate();
  console.log("Inside private Rouste");
  const userData = JSON.parse(localStorage.getItem("userData"));

  const urlParams = new URLSearchParams(window.location.search);
  const iopi = urlParams.get("iopi");
  console.log("------->", iopi);
  console.log(iopi === "2");
  iopi === "2"
    ? localStorage.setItem("covidDonation", "true")
    : localStorage.removeItem("covidDonation");

  if (userData != null && userData.userType === "Admin") {
    navigate("/money-details");
  }

  const userAddressDetails = JSON.parse(
    localStorage.getItem("userAddressDetails")
  );

  if (userAddressDetails.addressDetails == null) navigate("/addaddressdetails");

  if (!isAuthenticated) {
    isAuthenticated = userData !== null;
  }
  console.log(isAuthenticated);
  return isAuthenticated ? children : <Navigate to="/login" />;
};

function App() {
  const [paymentFlowCompleted, setPaymentFlowCompleted] = useState(false);
  const [paymentFlowCompletedRecurring, setPaymentFlowCompletedRecurring] =
    useState(false);

  const [isFundraiserInfoFilled, setIsFundraiserInfoFilled] = useState(false);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );

  const [isAuthenticated, setIsAuthanticated] = useState(false);
  const [userType, setUserType] = useState("");

  const handlePaymentFlowStep1Complete = () => {
    setPaymentFlowCompleted(true);
  };

  const handlePaymentFlowRecurring = () => {
    setPaymentFlowCompletedRecurring(true);
  };

  const handlePaymentFlowStep2Complete = () => {
    setPaymentFlowCompleted(true);
  };

  const handleFilledFunraiserInfo = () => {
    setIsFundraiserInfoFilled(true);
  };
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));

    setIsAuthanticated(userData !== null);

    console.log(userData);
    console.log(isAuthenticated);
  }, [userData]);

  useEffect(() => {
    console.log(paymentFlowCompleted);
  }, [paymentFlowCompleted]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<HomePage />} /> */}
          <Route
            path="/"
            element={
              <PrivateHomeAdminRoute>
                {" "}
                <HomePage />{" "}
              </PrivateHomeAdminRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PrivateLoginRoute auth={{ isAuthenticated }}>
                <LoginScreen />
              </PrivateLoginRoute>
            }
          />
          <Route
            path="/forgetpassword"
            element={
              <PrivateLoginRoute auth={{ isAuthenticated }}>
                <ForgetPassword />
              </PrivateLoginRoute>
            }
          />
          <Route
            path="/changepassword"
            element={
              <PrivateLoginRoute auth={{ isAuthenticated }}>
                <ChangePassword />
              </PrivateLoginRoute>
            }
          />
          <Route
            path="/createaccount"
            element={
              <PrivateLoginRoute auth={{ isAuthenticated }}>
                <CreateAccount />
              </PrivateLoginRoute>
            }
          />
          <Route
            path="/addaddressdetails"
            element={
              <PrivateLoginRoute auth={{ isAuthenticated }}>
                <AddAddressDetails />
              </PrivateLoginRoute>
            }
          />
          <Route path="/verifyaddress" element={<VerifyAddress />} />
          <Route path="/success" element={<Success />} />
          <Route path="/verifysuccess" element={<VerifySuccess />} />
          <Route path="/comingsoon" element={<ComingSoon />} />
          <Route
            path="/createfundraiser"
            element={
              <PrivateRouteForCreateFundraiser auth={{ isAuthenticated }}>
                <CreateFundraiser onComplete={handleFilledFunraiserInfo} />
              </PrivateRouteForCreateFundraiser>
            }
          />{" "}
          l
          <Route path="/browsefundraiser" element={<BrowseFundraiser />} />
          <Route path="/verifyuser" element={<VerifyUsers />} />
          <Route
            path="/donate"
            element={
              <PrivateRouteForDonate auth={{ isAuthenticated }}>
                <DonateNow onComplete={handlePaymentFlowRecurring} />
              </PrivateRouteForDonate>
            }
          />
          <Route
            path="/fundraiser/:id"
            element={<Fundraiser onComplete={handlePaymentFlowStep2Complete} />}
          />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/support" element={<Support />} />
          <Route
            path="/individual"
            element={isFundraiserInfoFilled ? <Other /> : <Navigate to="/" />}
          />
          <Route
            path="/organisation"
            element={isFundraiserInfoFilled ? <Self /> : <Navigate to="/" />}
          />
          <Route
            path="/transactiondetails"
            element={
              paymentFlowCompleted ? (
                <TransactionDetails
                  onComplete={handlePaymentFlowStep1Complete}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/stripepay"
            element={
              paymentFlowCompleted ? <StripePayments /> : <Navigate to="/" />
            }
          />
          <Route path="/dashboard" element={<VerifyUser />} />
          <Route
            path="/verifyusers"
            element={
              <PrivateAdminRoute auth={{ isAuthenticated }}>
                <VerifyUser />
              </PrivateAdminRoute>
            }
          />
          <Route
            path="/verifieduser"
            element={
              <PrivateAdminRoute auth={{ isAuthenticated }}>
                <VerifiedUser />
              </PrivateAdminRoute>
            }
          />
          <Route
            path="/rejecteduser"
            element={
              <PrivateAdminRoute auth={{ isAuthenticated }}>
                <ReejectedUser />
              </PrivateAdminRoute>
            }
          />
          <Route
            path="/verifyFundraiser"
            element={
              <PrivateAdminRoute auth={{ isAuthenticated }}>
                <VerifyFundraiser />
              </PrivateAdminRoute>
            }
          />
          <Route
            path="/donorshistory"
            element={
              <PrivateRoute auth={{ isAuthenticated }}>
                <DonorsHistory />
              </PrivateRoute>
            }
          />
          <Route
            path="/fundraiserhistory"
            element={
              <PrivateRoute auth={{ isAuthenticated }}>
                <FundraisersHistory />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/recurringdonation"
            element={
              <PrivateRoute auth={{ isAuthenticated }}>
                <RecurringDonation />
              </PrivateRoute>
            }
          />
          <Route path="/howwework" element={<HowWeWork />} />
          <Route path="/howweworkModal" element={<HowWeWorkModal />} />
          <Route
            path="/userprofile"
            element={
              <PrivateRoute auth={{ isAuthenticated }}>
                <CreateProfile />
              </PrivateRoute>
            }
          />
          <Route path="/add-bank-details" element={<AddBankDetails />} />
          <Route
            path="/list-of-causes"
            element={
              <PrivateRoute auth={{ isAuthenticated }}>
                <ViewCause />
              </PrivateRoute>
            }
          />
          <Route
            path="/money-details"
            element={
              <PrivateAdminRoute auth={{ isAuthenticated }}>
                <MoneyDetails />
              </PrivateAdminRoute>
            }
          />
          {/* paymentFlowCompleted ? (
                <TransactionDetails
                  onComplete={handlePaymentFlowStep1Complete}
                />
              ) : (
                <Navigate to="/" />
              ) */}
          {/* <Route
            path="/stripe-status"
            element={
              <PrivateRoute auth={{ isAuthenticated }}>
                <StripeStatus />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/stripe-donation"
            element={
              // <StripeRecurringPayment />
              paymentFlowCompletedRecurring ? (
                <StripeRecurringPayment />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/stripe-recurring-status"
            element={
              <PrivateRoute auth={{ isAuthenticated }}>
                <StripeRecurringStatus />
              </PrivateRoute>
            }
          />
          <Route
            path="/all-money"
            element={
              <PrivateAdminRoute auth={{ isAuthenticated }}>
                <AllMoney />
              </PrivateAdminRoute>
            }
          />
          <Route path="/total-money" element={<TotalMoney />} />
          <Route
            path="/all-transactions"
            element={
              <PrivateAdminRoute auth={{ isAuthenticated }}>
                <AllTransactions />
              </PrivateAdminRoute>
            }
          />
          <Route path="/donaton-cause" element={<DonationCause />} />
          <Route
            path="/all-fundraiser"
            element={
              <PrivateAdminRoute auth={{ isAuthenticated }}>
                <AllFundraisers />
              </PrivateAdminRoute>
            }
          />
          <Route path="/stripe-status" element={<StripeStatus />} />
          <Route path="/stripe-donation" element={<StripeRecurringPayment />} />
          {/* <Route
            path="/stripe-recurring-status"
            element={<StripeRecurringStatus />}
          /> */}
          <Route path="/oauth2/redirect" element={<OAuth2RedirectHandler />} />
          {/* ********************************PRABALTA 2.0 *********************************** */}
          <Route path="/donorlist" element={<DonorList />} />
          <Route path="/donorInfo" element={<DonorInformation />} />
          <Route path="/addDonorManually" element={<AddDonorManually />} />
          <Route path="/allDonationList" element={<AllDonationList />} />
          <Route path="/customReceipt" element={<CustomReceipt />} />
          <Route path="/fundraiserInfo" element={<FundraiserInformation />} />
          <Route path="/allFundraiserList" element={<AllFundraisersList />} />
          <Route path="/ngo-login" element={<NgoLogin />} />
          <Route path="/ngo-create-account" element={<NgoCreateAccount />} />
          <Route
            path="/add-ngo-addressdetails"
            element={<NgoAddressDetails />}
          />
          <Route
            path="/services-dashboard"
            element={<PrabaltaServicesDashboard />}
          />
          <Route path="/addDonations" element={<AddDonation />} />
          <Route path="/editDonations" element={<EditDonation />} />
          <Route path="/report" element={<Report />} />
          <Route
            path="/socialMediaCampaign"
            element={<SocialMediaCampaign />}
          />
          <Route path="/analyticsDashboard" element={<AnalyticsDashboard />} />
          <Route
            path="/fundraiserPerformance"
            element={<FundraiserPerformance />}
          />
          <Route path="/schedulePost" element={<SchedulePost />} />
          <Route path="/createPost" element={<CreatePost />} />
          <Route path="/fundraiserPerformance" element={<FundraiserPerformance />} />
          <Route path="/fundraiserPerformanceDetails" element={<FundraiserPerformanceDetails />} />
          
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>

      {/* <Login /> */}
      {/* <ChangePassword /> */}
      {/* <CreateAccount /> */}
      {/* <CreateProfile /> */}
      {/* <AddAddressDetails /> */}
      {/* <ForgetPassword />  */}
      {/* <AddBankDetails /> */}
      {/* <VerifyAddress /> */}
      {/* <Success /> */}
    </>
  );
}

export default App;
