import React, { useEffect } from "react";
import { ACCESS_TOKEN } from "../../utility/baseUrl";
import { useNavigate, useLocation } from "react-router-dom";
import { getRequestWithTokenWithoutData } from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";

const OAuth2RedirectHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");

    var results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const token = getUrlParameter("token");
  const error = getUrlParameter("error");
  const flag = getUrlParameter("flag");

  const fetchOauthData = async () => {
    try {
      const response = await getRequestWithTokenWithoutData(
        `${TQ_URL}/getOauthProfile`
      );

      console.log(response);

      const userDetails = response;
      console.log(userDetails);
      // localStorage.setItem(
      //   "userData",
      //   JSON.stringify({
      //     userId: userDetails.userId,
      //     userName: userDetails.userName,
      //     email: userDetails.email,
      //     phone: userDetails.phone,
      //     userType: response.userType,
      //     imageUrl: userDetails.imageUrl,
      //   })
      // );

      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: userDetails.user.userId,
          userName: userDetails.user.userName,
          email: userDetails.user.email,
          phone: userDetails.user.phone,
          userType: userDetails.user.userType,
          imageUrl: userDetails.user.imageUrl,
        })
      );

      localStorage.setItem(
        "userAddressDetails",
        JSON.stringify({ addressDetails: userDetails.addressDetail })
      );

      //console.log("User data has been set in localStorage:", data);
    } catch (error) {
      if (error.response) {
        console.error("Error fetching OAuth profile:", error.response.data);
      } else {
        console.error("Error fetching OAuth profile:", error.message);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        localStorage.setItem(ACCESS_TOKEN, token);
        await fetchOauthData();

        if (flag == 1) {
          const localStorageData = JSON.parse(localStorage.getItem("userData"));
          localStorage.setItem("OauthFlag", flag);
          console.log("Local Storage Dataaaa:", localStorageData.email);

          navigate(
            `/addaddressdetails?email=${encodeURIComponent(
              localStorageData.email
            )}`
          );
        } else navigate("/", { state: { from: location } });
      } else if (error) {
        navigate("/login", { state: { from: location, error } });
      }
    };
    fetchData();
  }, [error, fetchOauthData, navigate, location]);

  // The component doesn't render any JSX directly
  return null;
};

export default OAuth2RedirectHandler;
