
//export const TQ_URL = "http://localhost:8080";

export const TQ_URL = "https://prabalta.org:8087/usproject";

console.log("API URL:", TQ_URL);

export const ACCESS_TOKEN = "accessToken";

export const OAUTH2_REDIRECT_URI = "https://prabalta.org/oauth2/redirect";

export const GOOGLE_AUTH_URL =
  TQ_URL + "/oauth2/authorize/google?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL =
  TQ_URL + "/oauth2/authorize/facebook?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL =
  TQ_URL + "/oauth2/authorize/github?redirect_uri=" + OAUTH2_REDIRECT_URI;
